
import Vue from 'vue';

export default Vue.observable({
    giddict:
{0: 'English Opening, English Opening, Agincourt Defense',
 1: 'English Opening, English Opening, Anglo-Dutch Defense',
 2: 'English Opening, English Opening, Anglo-Grunfeld Defense',
 3: 'English Opening, English Opening, Anglo-Indian Defense',
 4: 'English Opening, English Opening, Anglo-Slav Variation',
 5: 'English Opening, English Opening, Carls-Bremen System',
 6: 'English Opening, English Opening, Caro-Kann Defensive System',
 7: 'English Opening, English Opening, Closed',
 8: 'English Opening, English Opening, Four Knights System',
 9: "English Opening, English Opening, King's English Variation",
 10: 'English Opening, English Opening, Main Line',
 11: 'English Opening, English Opening, Mikenas-Carls Variation',
 12: 'English Opening, English Opening, Neo-Catalan',
 13: 'English Opening, English Opening, Romanishin Gambit',
 14: 'English Opening, English Opening, Symmetrical',
 15: 'English Opening, English Opening, Symmetrical Variation',
 16: "King's Pawn Opening, Alekhine Defense, Balogh Variation",
 17: "King's Pawn Opening, Alekhine Defense, Exchange Variation",
 18: "King's Pawn Opening, Alekhine Defense, Four Pawns Attack",
 19: "King's Pawn Opening, Alekhine Defense, Kmoch Variation",
 20: "King's Pawn Opening, Alekhine Defense, Main Line",
 21: "King's Pawn Opening, Alekhine Defense, Modern Variation",
 22: "King's Pawn Opening, Alekhine Defense, Modern,Flohr Variation",
 23: "King's Pawn Opening, Alekhine Defense, Samisch Attack",
 24: "King's Pawn Opening, Alekhine Defense, Scandinavian Variation",
 25: "King's Pawn Opening, Alekhine Defense, Spielmann Gambit",
 26: "King's Pawn Opening, Alekhine Defense, Two Pawn Attack",
 27: "King's Pawn Opening, Bishop's Opening, Berlin Defense",
 28: "King's Pawn Opening, Bishop's Opening, Urusov Gambit",
 29: "King's Pawn Opening, Bishop's Opening, Vienna Hybrid",
 30: "King's Pawn Opening, Caro-Kann Defense, Accelerated Panov Attack",
 31: "King's Pawn Opening, Caro-Kann Defense, Advance",
 32: "King's Pawn Opening, Caro-Kann Defense, Advance Variation",
 33: "King's Pawn Opening, Caro-Kann Defense, Breyer Variation",
 34: "King's Pawn Opening, Caro-Kann Defense, Bronstein-Larsen Variation",
 35: "King's Pawn Opening, Caro-Kann Defense, Classical",
 36: "King's Pawn Opening, Caro-Kann Defense, Classical Variation",
 37: "King's Pawn Opening, Caro-Kann Defense, Edinburgh Variation",
 38: "King's Pawn Opening, Caro-Kann Defense, Exchange Variation",
 39: "King's Pawn Opening, Caro-Kann Defense, Forgacs Variation",
 40: "King's Pawn Opening, Caro-Kann Defense, Gurgenidze Counterattack",
 41: "King's Pawn Opening, Caro-Kann Defense, Gurgenidze System",
 42: "King's Pawn Opening, Caro-Kann Defense, Karpov Variation",
 43: "King's Pawn Opening, Caro-Kann Defense, Main Line",
 44: "King's Pawn Opening, Caro-Kann Defense, Maroczy Variation",
 45: "King's Pawn Opening, Caro-Kann Defense, Modern Variation",
 46: "King's Pawn Opening, Caro-Kann Defense, Panov Attack",
 47: "King's Pawn Opening, Caro-Kann Defense, Panov Attack,Fianchetto Defense",
 48: "King's Pawn Opening, Caro-Kann Defense, Panov Attack,Fianchetto Defense,Fianchetto Gambit",
 49: "King's Pawn Opening, Caro-Kann Defense, Panov-Botvinnik",
 50: "King's Pawn Opening, Caro-Kann Defense, Rasa-Studier Gambit",
 51: "King's Pawn Opening, Caro-Kann Defense, Tartakower Variation",
 52: "King's Pawn Opening, Caro-Kann Defense, Two Knights Attack",
 53: "King's Pawn Opening, Caro-Kann Defense, von Hennig Gambit",
 54: "King's Pawn Opening, Czech Defense, Main Line",
 55: "King's Pawn Opening, Four Knights Game, Bardeleben Variation",
 56: "King's Pawn Opening, Four Knights Game, Gunsberg Variation",
 57: "King's Pawn Opening, Four Knights Game, Italian Variation",
 58: "King's Pawn Opening, Four Knights Game, Janowski Variation",
 59: "King's Pawn Opening, Four Knights Game, Main Line",
 60: "King's Pawn Opening, Four Knights Game, Marshall Variation",
 61: "King's Pawn Opening, Four Knights Game, Nimzowitsch (Paulsen)",
 62: "King's Pawn Opening, Four Knights Game, Rubinstein Countergambit",
 63: "King's Pawn Opening, Four Knights Game, Scotch Variation",
 64: "King's Pawn Opening, Four Knights Game, Scotch Variation Accepted",
 65: "King's Pawn Opening, Four Knights Game, Spanish Variation",
 66: "King's Pawn Opening, Four Knights Game, Symmetrical",
 67: "King's Pawn Opening, French Defense, Advance Variation",
 68: "King's Pawn Opening, French Defense, Alekhine-Chatard Attack",
 69: "King's Pawn Opening, French Defense, Burn Variation",
 70: "King's Pawn Opening, French Defense, Classical",
 71: "King's Pawn Opening, French Defense, Classical Variation",
 72: "King's Pawn Opening, French Defense, Exchange Variation",
 73: "King's Pawn Opening, French Defense, King's Indian Attack",
 74: "King's Pawn Opening, French Defense, MacCutcheon Variation",
 75: "King's Pawn Opening, French Defense, Paulsen Variation",
 76: "King's Pawn Opening, French Defense, Rubinstein Variation",
 77: "King's Pawn Opening, French Defense, Steinitz Variation",
 78: "King's Pawn Opening, French Defense, Tarrasch",
 79: "King's Pawn Opening, French Defense, Tarrasch Variation",
 80: "King's Pawn Opening, French Defense, Winawer Variation",
 81: "King's Pawn Opening, French Defense, Wing Gambit",
 82: "King's Pawn Opening, Giuoco Piano, Main Line",
 83: "King's Pawn Opening, Italian Game, Classical Variation",
 84: "King's Pawn Opening, Italian Game, Evans Gambit",
 85: "King's Pawn Opening, Italian Game, Evans Gambit Declined",
 86: "King's Pawn Opening, Italian Game, Giuoco Pianissimo",
 87: "King's Pawn Opening, Italian Game, Giuoco Piano",
 88: "King's Pawn Opening, Italian Game, Hungarian Defense",
 89: "King's Pawn Opening, Italian Game, Main Line",
 90: "King's Pawn Opening, Italian Game, Schilling-Kostic Gambit",
 91: "King's Pawn Opening, Italian Game, Scotch Gambit",
 92: "King's Pawn Opening, Italian Game, Two Knights Defense",
 93: "King's Pawn Opening, King's Gambit, Falkbeer Countergambit",
 94: "King's Pawn Opening, King's Gambit Accepted, Abbazia Defense",
 95: "King's Pawn Opening, King's Gambit Accepted, Becker Defense",
 96: "King's Pawn Opening, King's Gambit Accepted, Bishop's Gambit",
 97: "King's Pawn Opening, King's Gambit Accepted, Bonsch-Osmolovsky Variation",
 98: "King's Pawn Opening, King's Gambit Accepted, Cunningham Defense",
 99: "King's Pawn Opening, King's Gambit Accepted, Fischer Defense",
 100: "King's Pawn Opening, King's Gambit Accepted, Kieseritzky Gambit",
 101: "King's Pawn Opening, King's Gambit Accepted, Modern Defense",
 102: "King's Pawn Opening, King's Gambit Accepted, Schallopp Defense",
 103: "King's Pawn Opening, King's Gambit Accepted, Traditional Variation",
 104: "King's Pawn Opening, King's Gambit Declined, Classical Variation",
 105: "King's Pawn Opening, King's Gambit Declined, Falkbeer",
 106: "King's Pawn Opening, King's Gambit Declined, Keene Defense",
 107: "King's Pawn Opening, King's Knight Opening, Normal Variation",
 108: "King's Pawn Opening, King's Pawn Game, Maroczy Defense",
 109: "King's Pawn Opening, King's Pawn Game, Tayler Opening",
 110: "King's Pawn Opening, Lion Defense, Lion's Jaw",
 111: "King's Pawn Opening, Modern Defense, Geller's System",
 112: "King's Pawn Opening, Modern Defense, Gurgenidze Defense",
 113: "King's Pawn Opening, Modern Defense, Lizard Defense",
 114: "King's Pawn Opening, Modern Defense, Main Line",
 115: "King's Pawn Opening, Modern Defense, Modern Pterodactyl",
 116: "King's Pawn Opening, Modern Defense, Pseudo-Austrian Attack",
 117: "King's Pawn Opening, Modern Defense, Semi-Averbakh Variation",
 118: "King's Pawn Opening, Modern Defense, Standard Defense",
 119: "King's Pawn Opening, Nimzo-Indian Defense, Panov Attack",
 120: "King's Pawn Opening, Nimzowitsch Defense, Scandinavian Variation",
 121: "King's Pawn Opening, Nimzowitsch Defense, Williams Variation",
 122: "King's Pawn Opening, Owen Defense, Main Line",
 123: "King's Pawn Opening, Philidor Defense, Exchange Variation",
 124: "King's Pawn Opening, Philidor Defense, Hanham Variation",
 125: "King's Pawn Opening, Philidor Defense, Larsen Variation",
 126: "King's Pawn Opening, Philidor Defense, Lion Variation",
 127: "King's Pawn Opening, Philidor Defense, Nimzowitsch",
 128: "King's Pawn Opening, Philidor Defense, Nimzowitsch Variation",
 129: "King's Pawn Opening, Pirc Defense, Austrian Attack",
 130: "King's Pawn Opening, Pirc Defense, Bayonet Attack",
 131: "King's Pawn Opening, Pirc Defense, Byrne Variation",
 132: "King's Pawn Opening, Pirc Defense, Chinese Variation",
 133: "King's Pawn Opening, Pirc Defense, Classical Variation",
 134: "King's Pawn Opening, Pirc Defense, Kholmov System",
 135: "King's Pawn Opening, Pirc Defense, Main Line",
 136: "King's Pawn Opening, Pirc Defense, Sveshnikov System",
 137: "King's Pawn Opening, Ponziani Opening, Caro Gambit",
 138: "King's Pawn Opening, Ponziani Opening, Jaenisch Counterattack",
 139: "King's Pawn Opening, Ponziani Opening, Leonhardt Variation",
 140: "King's Pawn Opening, Ponziani Opening, Romanishin Variation",
 141: "King's Pawn Opening, Ponziani Opening, Steinitz Variation",
 142: "King's Pawn Opening, Ponziani Opening, Vukovic Gambit",
 143: "King's Pawn Opening, Pterodactyl Defense, Sicilian",
 144: "King's Pawn Opening, Pterodactyl Defense, Western",
 145: "King's Pawn Opening, Rat Defense, Harmonist",
 146: "King's Pawn Opening, Robatsch (Modern) Defense, Main Line",
 147: "King's Pawn Opening, Russian Game, Classical Attack",
 148: "King's Pawn Opening, Russian Game, Cochrane Gambit",
 149: "King's Pawn Opening, Russian Game, Cozio (Lasker) Attack",
 150: "King's Pawn Opening, Russian Game, Damiano Variation",
 151: "King's Pawn Opening, Russian Game, French Attack",
 152: "King's Pawn Opening, Russian Game, Kaufmann Attack",
 153: "King's Pawn Opening, Russian Game, Modern Attack",
 154: "King's Pawn Opening, Russian Game, Nimzowitsch Attack",
 155: "King's Pawn Opening, Russian Game, Paulsen Attack",
 156: "King's Pawn Opening, Russian Game, Three Knights Game",
 157: "King's Pawn Opening, Ruy Lopez, Alapin Defense",
 158: "King's Pawn Opening, Ruy Lopez, Berlin Defense",
 159: "King's Pawn Opening, Ruy Lopez, Bird Variation",
 160: "King's Pawn Opening, Ruy Lopez, Classical Variation",
 161: "King's Pawn Opening, Ruy Lopez, Closed",
 162: "King's Pawn Opening, Ruy Lopez, Closed Variations",
 163: "King's Pawn Opening, Ruy Lopez, Columbus Variation",
 164: "King's Pawn Opening, Ruy Lopez, Cozio Defense",
 165: "King's Pawn Opening, Ruy Lopez, Exchange",
 166: "King's Pawn Opening, Ruy Lopez, Exchange Variation",
 167: "King's Pawn Opening, Ruy Lopez, Fianchetto Defense",
 168: "King's Pawn Opening, Ruy Lopez, Marshall",
 169: "King's Pawn Opening, Ruy Lopez, Marshall Attack",
 170: "King's Pawn Opening, Ruy Lopez, Morphy Defense",
 171: "King's Pawn Opening, Ruy Lopez, Open",
 172: "King's Pawn Opening, Ruy Lopez, Open Berlin Defense",
 173: "King's Pawn Opening, Ruy Lopez, Open Variations",
 174: "King's Pawn Opening, Ruy Lopez, Schliemann Defense",
 175: "King's Pawn Opening, Ruy Lopez, Steinitz Defense",
 176: "King's Pawn Opening, Ruy Lopez, Steinitz Defense Deferred",
 177: "King's Pawn Opening, Ruy Lopez, Wormald Attack",
 178: "King's Pawn Opening, Scandinavian Defense, Bronstein Variation",
 179: "King's Pawn Opening, Scandinavian Defense, Classical Variation",
 180: "King's Pawn Opening, Scandinavian Defense, Grunfeld Variation",
 181: "King's Pawn Opening, Scandinavian Defense, Icelandic-Palme Gambit",
 182: "King's Pawn Opening, Scandinavian Defense, Lasker Variation",
 183: "King's Pawn Opening, Scandinavian Defense, Main Line",
 184: "King's Pawn Opening, Scandinavian Defense, Marshall Variation",
 185: "King's Pawn Opening, Scandinavian Defense, Mieses-Kotroc Variation",
 186: "King's Pawn Opening, Scandinavian Defense, Modern Variation",
 187: "King's Pawn Opening, Scandinavian Defense, Panov Transfer",
 188: "King's Pawn Opening, Scandinavian Defense, Portuguese Variation",
 189: "King's Pawn Opening, Scandinavian Defense, Richter Variation",
 190: "King's Pawn Opening, Scotch Game, Blumenfeld Attack",
 191: "King's Pawn Opening, Scotch Game, Classical Variation",
 192: "King's Pawn Opening, Scotch Game, Göring Gambit",
 193: "King's Pawn Opening, Scotch Game, Haxo Gambit",
 194: "King's Pawn Opening, Scotch Game, Main Line",
 195: "King's Pawn Opening, Scotch Game, Malaniuk Variation",
 196: "King's Pawn Opening, Scotch Game, Meitner Variation",
 197: "King's Pawn Opening, Scotch Game, Mieses Variation",
 198: "King's Pawn Opening, Scotch Game, Romanishin Variation",
 199: "King's Pawn Opening, Scotch Game, Schmidt Variation",
 200: "King's Pawn Opening, Scotch Game, Scotch Gambit",
 201: "King's Pawn Opening, Scotch Game, Tartakower Variation",
 202: "King's Pawn Opening, Sicilian Defense, Accelerated Dragon",
 203: "King's Pawn Opening, Sicilian Defense, AcceleratedFianchetto",
 204: "King's Pawn Opening, Sicilian Defense, Alapin Variation",
 205: "King's Pawn Opening, Sicilian Defense, Boleslavsky Variation",
 206: "King's Pawn Opening, Sicilian Defense, Canal Attack",
 207: "King's Pawn Opening, Sicilian Defense, Chekhover Variation",
 208: "King's Pawn Opening, Sicilian Defense, Classical Variation",
 209: "King's Pawn Opening, Sicilian Defense, Classical Variation,Fianchetto Variation",
 210: "King's Pawn Opening, Sicilian Defense, Closed",
 211: "King's Pawn Opening, Sicilian Defense, Closed Variation",
 212: "King's Pawn Opening, Sicilian Defense, Closed Variation,Fianchetto Variation",
 213: "King's Pawn Opening, Sicilian Defense, Delayed Alapin",
 214: "King's Pawn Opening, Sicilian Defense, Delayed Alapin Variation",
 215: "King's Pawn Opening, Sicilian Defense, Dragon",
 216: "King's Pawn Opening, Sicilian Defense, Dragon Variation",
 217: "King's Pawn Opening, Sicilian Defense, Dragon Variation,Fianchetto Variation",
 218: "King's Pawn Opening, Sicilian Defense, Flohr Variation",
 219: "King's Pawn Opening, Sicilian Defense, Four Knights Variation",
 220: "King's Pawn Opening, Sicilian Defense, French Variation",
 221: "King's Pawn Opening, Sicilian Defense, Godiva Variation",
 222: "King's Pawn Opening, Sicilian Defense, Grand Prix Attack",
 223: "King's Pawn Opening, Sicilian Defense, Heidenfeld Variation",
 224: "King's Pawn Opening, Sicilian Defense, Hyperaccelerated Dragon",
 225: "King's Pawn Opening, Sicilian Defense, Hyperaccelerated Pterodactyl",
 226: "King's Pawn Opening, Sicilian Defense, HyperacceleratedFianchetto",
 227: "King's Pawn Opening, Sicilian Defense, Kalashnikov Variation",
 228: "King's Pawn Opening, Sicilian Defense, Kan",
 229: "King's Pawn Opening, Sicilian Defense, Kan Variation",
 230: "King's Pawn Opening, Sicilian Defense, Kupreichik Variation",
 231: "King's Pawn Opening, Sicilian Defense, Kveinis Variation",
 232: "King's Pawn Opening, Sicilian Defense, Lasker-Pelikan Variation",
 233: "King's Pawn Opening, Sicilian Defense, Löwenthal Variation",
 234: "King's Pawn Opening, Sicilian Defense, Main Line",
 235: "King's Pawn Opening, Sicilian Defense, Marshall Counterattack",
 236: "King's Pawn Opening, Sicilian Defense, McDonnell Attack",
 237: "King's Pawn Opening, Sicilian Defense, Modern Variations",
 238: "King's Pawn Opening, Sicilian Defense, Moscow Variation",
 239: "King's Pawn Opening, Sicilian Defense, Najdorf",
 240: "King's Pawn Opening, Sicilian Defense, Najdorf Variation",
 241: "King's Pawn Opening, Sicilian Defense, Nimzo-American Variation",
 242: "King's Pawn Opening, Sicilian Defense, Nimzowitsch Variation",
 243: "King's Pawn Opening, Sicilian Defense, Nyezhmetdinov-Rossolimo Attack",
 244: "King's Pawn Opening, Sicilian Defense, Nyezhmetdinov-Rossolimo Attack,Fianchetto Variation",
 245: "King's Pawn Opening, Sicilian Defense, O'Kelly Variation",
 246: "King's Pawn Opening, Sicilian Defense, Old Sicilian",
 247: "King's Pawn Opening, Sicilian Defense, Open",
 248: "King's Pawn Opening, Sicilian Defense, Paulsen Variation",
 249: "King's Pawn Opening, Sicilian Defense, Paulsen-Basman Defense",
 250: "King's Pawn Opening, Sicilian Defense, Pin Variation",
 251: "King's Pawn Opening, Sicilian Defense, Prins Variation",
 252: "King's Pawn Opening, Sicilian Defense, Richter-Rauzer",
 253: "King's Pawn Opening, Sicilian Defense, Richter-Rauzer Variation",
 254: "King's Pawn Opening, Sicilian Defense, Scheveningen",
 255: "King's Pawn Opening, Sicilian Defense, Scheveningen Variation",
 256: "King's Pawn Opening, Sicilian Defense, Scheveningen Variation,Fianchetto Variation",
 257: "King's Pawn Opening, Sicilian Defense, Smith-Morra Gambit",
 258: "King's Pawn Opening, Sicilian Defense, Smith-Morra Gambit Accepted",
 259: "King's Pawn Opening, Sicilian Defense, Smith-Morra Gambit Declined",
 260: "King's Pawn Opening, Sicilian Defense, Sozin",
 261: "King's Pawn Opening, Sicilian Defense, Sozin Attack",
 262: "King's Pawn Opening, Sicilian Defense, Sozin Attack,Flank Variation",
 263: "King's Pawn Opening, Sicilian Defense, Sozin,Fischer Variation",
 264: "King's Pawn Opening, Sicilian Defense, Staunton-Cochrane Variation",
 265: "King's Pawn Opening, Sicilian Defense, Velimirovic Attack",
 266: "King's Pawn Opening, Sicilian Defense, Venice Attack",
 267: "King's Pawn Opening, Three Knights Opening, Main Line",
 268: "King's Pawn Opening, Three Knights Opening, Steinitz Defense",
 269: "King's Pawn Opening, Vienna Game, Frankenstein-Dracula Variation",
 270: "King's Pawn Opening, Vienna Game, Mieses Variation",
 271: "King's Pawn Opening, Vienna Game, Paulsen Variation",
 272: "King's Pawn Opening, Vienna Game, Stanley Variation",
 273: "King's Pawn Opening, Vienna Game, Vienna Gambit",
 274: 'Other Opening, Bird Opening, Dutch Variation',
 275: 'Other Opening, Polish Opening, Main Line',
 276: "Queen's Pawn Opening, Benko Gambit, Fianchetto Variation",
 277: "Queen's Pawn Opening, Benko Gambit, Nd2 Variation",
 278: "Queen's Pawn Opening, Benko Gambit, Nescafe Frappe Attack",
 279: "Queen's Pawn Opening, Benko Gambit, Zaitsev Variation",
 280: "Queen's Pawn Opening, Benko Gambit Accepted, Dlugy Variation",
 281: "Queen's Pawn Opening, Benko Gambit Accepted, Fianchetto Variation",
 282: "Queen's Pawn Opening, Benko Gambit Accepted, Fully Accepted Variation",
 283: "Queen's Pawn Opening, Benko Gambit Accepted, King Walk Variation",
 284: "Queen's Pawn Opening, Benko Gambit Accepted, Modern Variation",
 285: "Queen's Pawn Opening, Benko Gambit Accepted, Pawn Return Variation",
 286: "Queen's Pawn Opening, Benko Gambit Accepted, Yugoslav",
 287: "Queen's Pawn Opening, Benko Gambit Declined, Main Line",
 288: "Queen's Pawn Opening, Benko Gambit Declined, Quiet Line",
 289: "Queen's Pawn Opening, Benko Gambit Declined, Sosonko Variation",
 290: "Queen's Pawn Opening, Benoni Defense, Benoni-Indian Defense",
 291: "Queen's Pawn Opening, Benoni Defense, Classical",
 292: "Queen's Pawn Opening, Benoni Defense, Classical Variation",
 293: "Queen's Pawn Opening, Benoni Defense, Czech Benoni Defense",
 294: "Queen's Pawn Opening, Benoni Defense, Fianchetto Variation",
 295: "Queen's Pawn Opening, Benoni Defense, Four Pawns Attack",
 296: "Queen's Pawn Opening, Benoni Defense, Hromodka System",
 297: "Queen's Pawn Opening, Benoni Defense, King's Indian System",
 298: "Queen's Pawn Opening, Benoni Defense, King's Pawn Line",
 299: "Queen's Pawn Opening, Benoni Defense, Knight's Tour Variation",
 300: "Queen's Pawn Opening, Benoni Defense, Main Line",
 301: "Queen's Pawn Opening, Benoni Defense, Mikenas Variation",
 302: "Queen's Pawn Opening, Benoni Defense, Modern Variation",
 303: "Queen's Pawn Opening, Benoni Defense, Old Benoni",
 304: "Queen's Pawn Opening, Benoni Defense, Taimanov Variation",
 305: "Queen's Pawn Opening, Benoni Defense, Uhlmann Variation",
 306: "Queen's Pawn Opening, Benoni Defense, Woozle",
 307: "Queen's Pawn Opening, Blumenfeld Countergambit, Dus-Khotimirsky Variation",
 308: "Queen's Pawn Opening, Blumenfeld Countergambit, Main Line",
 309: "Queen's Pawn Opening, Blumenfeld Countergambit, Spielmann Variation",
 310: "Queen's Pawn Opening, Blumenfeld Countergambit Accepted, Main Line",
 311: "Queen's Pawn Opening, Bogo-Indian Defense, Exchange Variation",
 312: "Queen's Pawn Opening, Bogo-Indian Defense, Grunfeld Variation",
 313: "Queen's Pawn Opening, Bogo-Indian Defense, Nimzowitsch Variation",
 314: "Queen's Pawn Opening, Bogo-Indian Defense, Retreat Variation",
 315: "Queen's Pawn Opening, Bogo-Indian Defense, Vitolins Variation",
 316: "Queen's Pawn Opening, Bogo-Indian Defense, Wade-Smyslov Variation",
 317: "Queen's Pawn Opening, Budapest Defense, Adler Variation",
 318: "Queen's Pawn Opening, Budapest Defense, Alekhine Variation",
 319: "Queen's Pawn Opening, Budapest Defense, Main Line",
 320: "Queen's Pawn Opening, Budapest Defense, Rubinstein Variation",
 321: "Queen's Pawn Opening, Catalan Opening, Closed",
 322: "Queen's Pawn Opening, Catalan Opening, Closed Variation",
 323: "Queen's Pawn Opening, Catalan Opening, Hungarian Gambit",
 324: "Queen's Pawn Opening, Catalan Opening, Main Line",
 325: "Queen's Pawn Opening, Catalan Opening, Open Defense",
 326: "Queen's Pawn Opening, Colle System, Traditional Colle",
 327: "Queen's Pawn Opening, Dutch Defense, Alekhine Variation",
 328: "Queen's Pawn Opening, Dutch Defense, Blackburne Variation",
 329: "Queen's Pawn Opening, Dutch Defense, Blackmar's Second Gambit",
 330: "Queen's Pawn Opening, Dutch Defense, Classical Variation",
 331: "Queen's Pawn Opening, Dutch Defense, Fianchetto Attack",
 332: "Queen's Pawn Opening, Dutch Defense, Fianchetto Variation",
 333: "Queen's Pawn Opening, Dutch Defense, Ilyin-Zhenevsky",
 334: "Queen's Pawn Opening, Dutch Defense, Leningrad Variation",
 335: "Queen's Pawn Opening, Dutch Defense, Nimzo-Dutch Variation",
 336: "Queen's Pawn Opening, Dutch Defense, Queen's Knight Variation",
 337: "Queen's Pawn Opening, Dutch Defense, Raphael Variation",
 338: "Queen's Pawn Opening, Dutch Defense, Semi-Leningrad Variation",
 339: "Queen's Pawn Opening, Dutch Defense, Staunton Gambit",
 340: "Queen's Pawn Opening, Dutch Defense, Stonewall Variation",
 341: "Queen's Pawn Opening, East Indian Defense, Main Line",
 342: "Queen's Pawn Opening, Grunfeld Defense, Botvinnik Variation",
 343: "Queen's Pawn Opening, Grunfeld Defense, Brinckmann Attack",
 344: "Queen's Pawn Opening, Grunfeld Defense, Counterthrust Variation",
 345: "Queen's Pawn Opening, Grunfeld Defense, Exchange",
 346: "Queen's Pawn Opening, Grunfeld Defense, Exchange Variation",
 347: "Queen's Pawn Opening, Grunfeld Defense, Flohr Variation",
 348: "Queen's Pawn Opening, Grunfeld Defense, Lutikov Variation",
 349: "Queen's Pawn Opening, Grunfeld Defense, Main Line",
 350: "Queen's Pawn Opening, Grunfeld Defense, Makogonov Variation",
 351: "Queen's Pawn Opening, Grunfeld Defense, Opocensky Variation",
 352: "Queen's Pawn Opening, Grunfeld Defense, Russian",
 353: "Queen's Pawn Opening, Grunfeld Defense, Russian Variation",
 354: "Queen's Pawn Opening, Grunfeld Defense, Stockholm Variation",
 355: "Queen's Pawn Opening, Grunfeld Defense, Three Knights Variation",
 356: "Queen's Pawn Opening, Grunfeld Defense, Zaitsev Gambit",
 357: "Queen's Pawn Opening, Indian Game, Anti-Grunfeld",
 358: "Queen's Pawn Opening, Indian Game, Anti-Nimzo-Indian",
 359: "Queen's Pawn Opening, Indian Game, Dzindzi-Indian Defense",
 360: "Queen's Pawn Opening, Indian Game, King's Indian Variation",
 361: "Queen's Pawn Opening, Indian Game, Knights Variation",
 362: "Queen's Pawn Opening, Indian Game, London System",
 363: "Queen's Pawn Opening, Indian Game, Normal Variation",
 364: "Queen's Pawn Opening, Indian Game, Przepiorka Variation",
 365: "Queen's Pawn Opening, Indian Game, Pseudo-Benko",
 366: "Queen's Pawn Opening, Indian Game, Spielmann-Indian",
 367: "Queen's Pawn Opening, Indian Game, Tartakower Attack",
 368: "Queen's Pawn Opening, Indian Game, Wade-Tartakower Defense",
 369: "Queen's Pawn Opening, Kangaroo Defense, Main Line",
 370: "Queen's Pawn Opening, King's Indian, Averbakh",
 371: "Queen's Pawn Opening, King's Indian, Fianchetto",
 372: "Queen's Pawn Opening, King's Indian, Orthodox",
 373: "Queen's Pawn Opening, King's Indian, Samisch",
 374: "Queen's Pawn Opening, King's Indian Defense, Accelerated Averbakh Variation",
 375: "Queen's Pawn Opening, King's Indian Defense, Averbakh Variation",
 376: "Queen's Pawn Opening, King's Indian Defense, Exchange Variation",
 377: "Queen's Pawn Opening, King's Indian Defense, Fianchetto Variation",
 378: "Queen's Pawn Opening, King's Indian Defense, Four Pawns Attack",
 379: "Queen's Pawn Opening, King's Indian Defense, Kazakh Variation",
 380: "Queen's Pawn Opening, King's Indian Defense, Kramer Variation",
 381: "Queen's Pawn Opening, King's Indian Defense, Larsen Variation",
 382: "Queen's Pawn Opening, King's Indian Defense, Main Line",
 383: "Queen's Pawn Opening, King's Indian Defense, Makogonov Variation",
 384: "Queen's Pawn Opening, King's Indian Defense, Normal Variation",
 385: "Queen's Pawn Opening, King's Indian Defense, Orthodox Variation",
 386: "Queen's Pawn Opening, King's Indian Defense, Petrosian Variation",
 387: "Queen's Pawn Opening, King's Indian Defense, Samisch Variation",
 388: "Queen's Pawn Opening, King's Indian Defense, Semi-Averbakh System",
 389: "Queen's Pawn Opening, King's Indian Defense, Semi-Classical Variation",
 390: "Queen's Pawn Opening, King's Indian Defense, Smyslov Variation",
 391: "Queen's Pawn Opening, King's Indian Defense, Steiner Attack",
 392: "Queen's Pawn Opening, London System, Main Line",
 393: "Queen's Pawn Opening, Mexican Defense, Main Line",
 394: "Queen's Pawn Opening, Modern Defense, Averbakh System",
 395: "Queen's Pawn Opening, Modern Defense, Beefeater Variation",
 396: "Queen's Pawn Opening, Modern Defense, Kotov Variation",
 397: "Queen's Pawn Opening, Modern Defense, Main Line",
 398: "Queen's Pawn Opening, Modern Defense, Randspringer Variation",
 399: "Queen's Pawn Opening, Neo-Grunfeld Defense, Classical Variation",
 400: "Queen's Pawn Opening, Neo-Grunfeld Defense, Delayed Exchange Variation",
 401: "Queen's Pawn Opening, Neo-Grunfeld Defense, Exchange Variation",
 402: "Queen's Pawn Opening, Neo-Grunfeld Defense, Goglidze Attack",
 403: "Queen's Pawn Opening, Neo-Grunfeld Defense, Main Line",
 404: "Queen's Pawn Opening, Neo-Grunfeld Defense, Non- or Delayed Fianchetto",
 405: "Queen's Pawn Opening, Neo-Grunfeld Defense, Ultra-delayed Exchange Variation",
 406: "Queen's Pawn Opening, Nimzo-Indian Defense, Classical",
 407: "Queen's Pawn Opening, Nimzo-Indian Defense, Classical Variation",
 408: "Queen's Pawn Opening, Nimzo-Indian Defense, Fischer Variation",
 409: "Queen's Pawn Opening, Nimzo-Indian Defense, Hübner Variation",
 410: "Queen's Pawn Opening, Nimzo-Indian Defense, Kmoch Variation",
 411: "Queen's Pawn Opening, Nimzo-Indian Defense, Leningrad Variation",
 412: "Queen's Pawn Opening, Nimzo-Indian Defense, Main Line",
 413: "Queen's Pawn Opening, Nimzo-Indian Defense, Normal Variation",
 414: "Queen's Pawn Opening, Nimzo-Indian Defense, Ragozin Defense",
 415: "Queen's Pawn Opening, Nimzo-Indian Defense, Ragozin Variation",
 416: "Queen's Pawn Opening, Nimzo-Indian Defense, Reshevsky Variation",
 417: "Queen's Pawn Opening, Nimzo-Indian Defense, Romanishin Variation",
 418: "Queen's Pawn Opening, Nimzo-Indian Defense, Samisch",
 419: "Queen's Pawn Opening, Nimzo-Indian Defense, Samisch Variation",
 420: "Queen's Pawn Opening, Nimzo-Indian Defense, Simagin Variation",
 421: "Queen's Pawn Opening, Nimzo-Indian Defense, Spielmann Variation",
 422: "Queen's Pawn Opening, Nimzo-Indian Defense, St. Petersburg Variation",
 423: "Queen's Pawn Opening, Nimzo-Indian Defense, Three Knights Variation",
 424: "Queen's Pawn Opening, Old Benoni Defense, Main Line",
 425: "Queen's Pawn Opening, Old Indian, Czech Variation",
 426: "Queen's Pawn Opening, Old Indian Defense, Dus-Khotimirsky Variation",
 427: "Queen's Pawn Opening, Old Indian Defense, Janowski Variation",
 428: "Queen's Pawn Opening, Old Indian Defense, Main Line",
 429: "Queen's Pawn Opening, Old Indian Defense, Normal Variation",
 430: "Queen's Pawn Opening, Old Indian Defense, Tartakower-Indian",
 431: "Queen's Pawn Opening, Old Indian Defense, Two Knights Variation",
 432: "Queen's Pawn Opening, Old Indian Defense, Ukrainian Variation",
 433: "Queen's Pawn Opening, Pterodactyl Defense, Main Line",
 434: "Queen's Pawn Opening, Queen's Gambit Accepted, Alekhine Defense",
 435: "Queen's Pawn Opening, Queen's Gambit Accepted, Bogoljubov Defense",
 436: "Queen's Pawn Opening, Queen's Gambit Accepted, Central Variation",
 437: "Queen's Pawn Opening, Queen's Gambit Accepted, Classical Defense",
 438: "Queen's Pawn Opening, Queen's Gambit Accepted, Godes Variation",
 439: "Queen's Pawn Opening, Queen's Gambit Accepted, Gunsberg Defense",
 440: "Queen's Pawn Opening, Queen's Gambit Accepted, Janowski-Larsen Variation",
 441: "Queen's Pawn Opening, Queen's Gambit Accepted, Linares Variation",
 442: "Queen's Pawn Opening, Queen's Gambit Accepted, Main Line",
 443: "Queen's Pawn Opening, Queen's Gambit Accepted, Mannheim Variation",
 444: "Queen's Pawn Opening, Queen's Gambit Accepted, Normal Variation",
 445: "Queen's Pawn Opening, Queen's Gambit Accepted, Old Variation",
 446: "Queen's Pawn Opening, Queen's Gambit Accepted, Rosenthal Variation",
 447: "Queen's Pawn Opening, Queen's Gambit Accepted, Showalter Variation",
 448: "Queen's Pawn Opening, Queen's Gambit Accepted, Smyslov Variation",
 449: "Queen's Pawn Opening, Queen's Gambit Accepted, Winawer Defense",
 450: "Queen's Pawn Opening, Queen's Gambit Declined, Albin Countergambit",
 451: "Queen's Pawn Opening, Queen's Gambit Declined, Anti-Tartakower Variation",
 452: "Queen's Pawn Opening, Queen's Gambit Declined, Barmen Variation",
 453: "Queen's Pawn Opening, Queen's Gambit Declined, Been-Koomen Variation",
 454: "Queen's Pawn Opening, Queen's Gambit Declined, Cambridge Springs Defense",
 455: "Queen's Pawn Opening, Queen's Gambit Declined, Capablanca",
 456: "Queen's Pawn Opening, Queen's Gambit Declined, Capablanca Variation",
 457: "Queen's Pawn Opening, Queen's Gambit Declined, Charousek (Petrosian) Variation",
 458: "Queen's Pawn Opening, Queen's Gambit Declined, Exchange Variation",
 459: "Queen's Pawn Opening, Queen's Gambit Declined, Harrwitz Attack",
 460: "Queen's Pawn Opening, Queen's Gambit Declined, Hastings Variation",
 461: "Queen's Pawn Opening, Queen's Gambit Declined, Janowski Variation",
 462: "Queen's Pawn Opening, Queen's Gambit Declined, Lasker Defense",
 463: "Queen's Pawn Opening, Queen's Gambit Declined, Main Line",
 464: "Queen's Pawn Opening, Queen's Gambit Declined, Manhattan Variation",
 465: "Queen's Pawn Opening, Queen's Gambit Declined, Miles Variation",
 466: "Queen's Pawn Opening, Queen's Gambit Declined, Modern",
 467: "Queen's Pawn Opening, Queen's Gambit Declined, Modern Variation",
 468: "Queen's Pawn Opening, Queen's Gambit Declined, Neo-Orthodox Variation",
 469: "Queen's Pawn Opening, Queen's Gambit Declined, Normal Defense",
 470: "Queen's Pawn Opening, Queen's Gambit Declined, Orthodox Defense",
 471: "Queen's Pawn Opening, Queen's Gambit Declined, Pseudo-Tarrasch Variation",
 472: "Queen's Pawn Opening, Queen's Gambit Declined, Queen's Knight Variation",
 473: "Queen's Pawn Opening, Queen's Gambit Declined, Ragozin Defense",
 474: "Queen's Pawn Opening, Queen's Gambit Declined, Semi-Slav",
 475: "Queen's Pawn Opening, Queen's Gambit Declined, Semi-Tarrasch",
 476: "Queen's Pawn Opening, Queen's Gambit Declined, Semi-Tarrasch Defense",
 477: "Queen's Pawn Opening, Queen's Gambit Declined, Semmering Variation",
 478: "Queen's Pawn Opening, Queen's Gambit Declined, Slav",
 479: "Queen's Pawn Opening, Queen's Gambit Declined, Tarrasch Defense",
 480: "Queen's Pawn Opening, Queen's Gambit Declined, Tartakower Defense",
 481: "Queen's Pawn Opening, Queen's Gambit Declined, Tartakower Variation",
 482: "Queen's Pawn Opening, Queen's Gambit Declined, Three Knights Variation",
 483: "Queen's Pawn Opening, Queen's Gambit Declined, Traditional Variation",
 484: "Queen's Pawn Opening, Queen's Gambit Declined, Vienna Variation",
 485: "Queen's Pawn Opening, Queen's Gambit Refused, Albin Countergambit",
 486: "Queen's Pawn Opening, Queen's Gambit Refused, Austrian Defense",
 487: "Queen's Pawn Opening, Queen's Gambit Refused, Baltic Defense",
 488: "Queen's Pawn Opening, Queen's Gambit Refused, Chigorin Defense",
 489: "Queen's Pawn Opening, Queen's Indian, Buerger Variation",
 490: "Queen's Pawn Opening, Queen's Indian Accelerated, Main Line",
 491: "Queen's Pawn Opening, Queen's Indian Defense, Anti-Queen's Indian System",
 492: "Queen's Pawn Opening, Queen's Indian Defense, Capablanca Variation",
 493: "Queen's Pawn Opening, Queen's Indian Defense, Classical Variation",
 494: "Queen's Pawn Opening, Queen's Indian Defense, Euwe Variation",
 495: "Queen's Pawn Opening, Queen's Indian Defense, Fianchetto Variation",
 496: "Queen's Pawn Opening, Queen's Indian Defense, Kasparov Variation",
 497: "Queen's Pawn Opening, Queen's Indian Defense, Kasparov-Petrosian Variation",
 498: "Queen's Pawn Opening, Queen's Indian Defense, Main Line",
 499: "Queen's Pawn Opening, Queen's Indian Defense, Marienbad System",
 500: "Queen's Pawn Opening, Queen's Indian Defense, Miles Variation",
 501: "Queen's Pawn Opening, Queen's Indian Defense, Opocensky Variation",
 502: "Queen's Pawn Opening, Queen's Indian Defense, Petrosian Variation",
 503: "Queen's Pawn Opening, Queen's Indian Defense, Riumin Variation",
 504: "Queen's Pawn Opening, Queen's Indian Defense, Spassky System",
 505: "Queen's Pawn Opening, Queen's Indian Defense, Yates Variation",
 506: "Queen's Pawn Opening, Queen's Pawn, Main Line",
 507: "Queen's Pawn Opening, Queen's Pawn Game, Barry Attack",
 508: "Queen's Pawn Opening, Queen's Pawn Game, Chigorin Variation",
 509: "Queen's Pawn Opening, Queen's Pawn Game, Colle System",
 510: "Queen's Pawn Opening, Queen's Pawn Game, Levitsky Attack",
 511: "Queen's Pawn Opening, Queen's Pawn Game, London System",
 512: "Queen's Pawn Opening, Queen's Pawn Game, Symmetrical Variation",
 513: "Queen's Pawn Opening, Queen's Pawn Game, Torre Attack",
 514: "Queen's Pawn Opening, Queen's Pawn Game, Veresov Attack",
 515: "Queen's Pawn Opening, Queen's Pawn Game, Zukertort Variation",
 516: "Queen's Pawn Opening, Rat Defense, English Rat",
 517: "Queen's Pawn Opening, Richter-Veresov Attack, Main Line",
 518: "Queen's Pawn Opening, Robatsch Defense, Main Line",
 519: "Queen's Pawn Opening, Rubinstein Opening, Main Line",
 520: "Queen's Pawn Opening, Semi-Benoni, Main Line",
 521: "Queen's Pawn Opening, Semi-Slav Defense, Accelerated Meran Variation",
 522: "Queen's Pawn Opening, Semi-Slav Defense, Accelerated Move Order",
 523: "Queen's Pawn Opening, Semi-Slav Defense, Anti-Moscow Gambit",
 524: "Queen's Pawn Opening, Semi-Slav Defense, Bogoljubov Variation",
 525: "Queen's Pawn Opening, Semi-Slav Defense, Botvinnik System",
 526: "Queen's Pawn Opening, Semi-Slav Defense, Botvinnik Variation",
 527: "Queen's Pawn Opening, Semi-Slav Defense, Chigorin Defense",
 528: "Queen's Pawn Opening, Semi-Slav Defense, Main Line",
 529: "Queen's Pawn Opening, Semi-Slav Defense, Marshall Gambit",
 530: "Queen's Pawn Opening, Semi-Slav Defense, Meran Variation",
 531: "Queen's Pawn Opening, Semi-Slav Defense, Normal Variation",
 532: "Queen's Pawn Opening, Semi-Slav Defense, Romih Variation",
 533: "Queen's Pawn Opening, Semi-Slav Defense, Rubinstein (Anti-Meran) System",
 534: "Queen's Pawn Opening, Semi-Slav Defense, Stoltz Variation",
 535: "Queen's Pawn Opening, Semi-Slav Defense Accepted, Main Line",
 536: "Queen's Pawn Opening, Slav Defense, Alapin Variation",
 537: "Queen's Pawn Opening, Slav Defense, Alekhine Variation",
 538: "Queen's Pawn Opening, Slav Defense, Breyer Variation",
 539: "Queen's Pawn Opening, Slav Defense, Chameleon Variation",
 540: "Queen's Pawn Opening, Slav Defense, Czech Variation",
 541: "Queen's Pawn Opening, Slav Defense, Exchange Variation",
 542: "Queen's Pawn Opening, Slav Defense, Geller Gambit",
 543: "Queen's Pawn Opening, Slav Defense, Main Line",
 544: "Queen's Pawn Opening, Slav Defense, Modern",
 545: "Queen's Pawn Opening, Slav Defense, Modern Line",
 546: "Queen's Pawn Opening, Slav Defense, Quiet Variation",
 547: "Queen's Pawn Opening, Slav Defense, Schlechter Variation",
 548: "Queen's Pawn Opening, Slav Defense, Slav Gambit",
 549: "Queen's Pawn Opening, Slav Defense, Smyslov Variation",
 550: "Queen's Pawn Opening, Slav Defense, Soultanbeieff Variation",
 551: "Queen's Pawn Opening, Slav Defense, Steiner Variation",
 552: "Queen's Pawn Opening, Slav Defense, Süchting Variation",
 553: "Queen's Pawn Opening, Slav Defense, Two Knights Attack",
 554: "Queen's Pawn Opening, Slav Defense, Winawer Countergambit",
 555: "Queen's Pawn Opening, Tarrasch Defense, Classical Variation",
 556: "Queen's Pawn Opening, Tarrasch Defense, Main Line",
 557: "Queen's Pawn Opening, Tarrasch Defense, Prague Variation",
 558: "Queen's Pawn Opening, Tarrasch Defense, Rubinstein System",
 559: "Queen's Pawn Opening, Tarrasch Defense, Swedish Variation",
 560: "Queen's Pawn Opening, Tarrasch Defense, Symmetrical Variation",
 561: "Queen's Pawn Opening, Tarrasch Defense, Tarrasch Gambit",
 562: "Queen's Pawn Opening, Tarrasch Defense, Two Knights Variation",
 563: "Queen's Pawn Opening, Tarrasch Defense, Wagner Variation",
 564: "Queen's Pawn Opening, Torre Attack, Classical Defense",
 565: "Queen's Pawn Opening, Torre Attack, Main Line",
 566: "Queen's Pawn Opening, Trompowsky Attack, Classical Defense",
 567: "Queen's Pawn Opening, Trompowsky Attack, Edge Variation",
 568: "Queen's Pawn Opening, Trompowsky Attack, Main Line",
 569: "Queen's Pawn Opening, Trompowsky Attack, Poisoned Pawn Variation",
 570: "Queen's Pawn Opening, Trompowsky Attack, Raptor Variation",
 571: "Queen's Pawn Opening, Wade Defense, Main Line",
 572: "Queen's Pawn Opening, Yusupov-Rubinstein System, Main Line",
 573: "Zuckertort Opening, King's Indian Attack, French Variation",
 574: "Zuckertort Opening, King's Indian Attack, Keres Variation",
 575: "Zuckertort Opening, King's Indian Attack, Main Line",
 576: "Zuckertort Opening, King's Indian Attack, Pachman System",
 577: "Zuckertort Opening, King's Indian Attack, Sicilian Variation",
 578: "Zuckertort Opening, King's Indian Attack, Smyslov Variation",
 579: "Zuckertort Opening, King's Indian Attack, Symmetrical Defense",
 580: "Zuckertort Opening, King's Indian Attack, Yugoslav Variation",
 581: 'Zuckertort Opening, Nimzo-Larsen Attack, Classical Variation',
 582: 'Zuckertort Opening, Reti Opening, Advance Variation',
 583: 'Zuckertort Opening, Reti Opening, Main Line',
 584: 'Zuckertort Opening, Reti Opening, Reti Gambit Accepted',
 585: 'Zuckertort Opening, Zukertort Opening, Lisitsyn Gambit',
 586: 'Zuckertort Opening, Zukertort Opening, Sicilian Invitation'}
});

